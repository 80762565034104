import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { ThemedPageSection } from ".";
import { useSectionTheme } from "../../styles/pageThemes";
import { YoutubeEmbed } from "../EmbeddedContent";
import { Hyperlink } from "../Link/Hyperlink";
import { Heading } from "../Typography/Heading";
import { Paragraph } from "../Typography/Paragraph";
import { Flex } from "../ThemeFlex";
import { GridGutterRows } from "../GridGutters";
import { gridSquares } from "../../styles/grid";

export const CareersPageProductIntro = () => {
    const {
        allSanityProduct: { products },
    } = useStaticQuery(graphql`
        query {
            allSanityProduct(sort: { fields: atlassianProductId }) {
                products: nodes {
                    appId: atlassianProductId
                    icon {
                        asset {
                            gatsbyImageData(placeholder: NONE)
                        }
                    }
                    title
                    productPage {
                        slug {
                            current
                        }
                    }
                    productListing {
                        title
                        buttonText
                        _rawContent
                    }
                }
            }
        }
    `);

    return (
        <ThemeProvider theme={useSectionTheme("meteorShower")}>
            <ThemedPageSection>
                <Flex alignItems="center" flexDirection="column">
                    <MissionTitle>
                        Our mission is to help teams be agile. We're building
                        remarkable products and an outstanding team to do so.
                    </MissionTitle>
                    <GridGutterRows gutters={2} />
                </Flex>
                <ProductIntroWrapper>
                    <YoutubeEmbed
                        url="https://www.youtube.com/watch?v=dbOz52c4bbY"
                        size="large"
                    />
                    <ProductText>
                        <MissionBlurb>
                            Easy Agile is an Australian founded and{" "}
                            <Hyperlink href="https://en.wikipedia.org/wiki/Bootstrapping#Business">
                                bootstrapped
                            </Hyperlink>{" "}
                            company. We are a proud, profitable business.
                        </MissionBlurb>
                        <MissionBlurb>
                            We craft solutions to make every agile team more
                            effective. We have four products built on top of
                            Atlassian's flagship product Jira, sold via the
                            Atlassian Marketplace.
                        </MissionBlurb>

                        <ProductGrid>
                            {products.map((product) => (
                                <Hyperlink
                                    key={product.appId}
                                    href={`/products/${
                                        product?.productPage?.slug?.current ||
                                        ""
                                    }`}
                                >
                                    <Product
                                        flexDirection="column"
                                        alignItems="center"
                                    >
                                        <ProductImage
                                            title={product.title}
                                            image={
                                                product.icon.asset
                                                    .gatsbyImageData
                                            }
                                            alt={product.title}
                                        />
                                        <ProductTitleHeading level="h6">
                                            {product.title}
                                        </ProductTitleHeading>
                                    </Product>
                                </Hyperlink>
                            ))}
                        </ProductGrid>
                    </ProductText>
                </ProductIntroWrapper>
                <GridGutterRows gutters={2} />
            </ThemedPageSection>
        </ThemeProvider>
    );
};

// FW-879 - add responsive BodyCopyBold
const CenteredBodyCopyBold = styled(Paragraph)<{ children }>`
    text-align: center;
    font-weight: 700;

    ${(p) => p.theme.media("lg")} {
        font-size: 24px;
        max-width: 976px;
    }
    ${(p) => p.theme.media("xxl")} {
        font-size: 36px;
        max-width: 976px;
    } ;
`;

// FW-879 - add responsive BodyCopy
const CenteredBodyCopy = styled(Paragraph)<{ children }>`
    text-align: center;

    margin-top: 0px;
    ${(p) => p.theme.media("sm")} {
        font-size: 20px;
    } ;
`;

const MissionTitle = styled(CenteredBodyCopyBold)`
    ${(p) => p.theme.media("lg")} {
        max-width: 660px;
    }
    ${(p) => p.theme.media("xl")} {
        max-width: 976px;
    } ;
`;
const MissionBlurb = styled(CenteredBodyCopy)`
    ${(p) => p.theme.media("lg")} {
        text-align: left;
    } ;
`;

const ProductIntroWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${gridSquares(4)};
    align-items: center;

    ${(p) => p.theme.media("lg")} {
        grid-template-columns: 2fr 1fr;
    }
    ${(p) => p.theme.media("xl")} {
        grid-template-columns: 3fr 1fr;
    }
`;

const ProductText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const ProductGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: center;
    gap: 24px;

    ${(p) => p.theme.media("sm")} {
        grid-template-columns: repeat(4, auto);
    } ;
`;

const ProductImage = styled(GatsbyImage)`
    && {
        width: 32px;
        height: 32px;
        border-radius: ${(p) => p.theme.borderRadius.s};
        border: 1px solid
            ${(p) => (p.title === "Roadmaps" ? "white" : "transparent")};
    }
`;

const ProductTitleHeading = styled(Heading)`
    margin-top: 12px;
`;

// product titles hide until hover on XL screens but you need to be able
// to see if you hover over the icon, not just the text
const Product = styled(Flex)`
    ${(p) => p.theme.media("xl")} {
        ${ProductTitleHeading} {
            opacity: 0;
            transition: opacity 200ms;
        }
        &:hover {
            ${ProductTitleHeading} {
                opacity: 1;
            }
        }
    }
`;
