import React, { useMemo } from "react";
import styled, { ThemeProvider } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { useSectionTheme } from "../../styles/pageThemes";
import { ThemedPageSection } from ".";
import { Heading } from "../Typography/Heading";
import { Paragraph } from "../Typography/Paragraph";
import { Hyperlink } from "../Link/Hyperlink";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ShowWhenGreaterThan, ShowWhenLessThan } from "../Breakpoints";
import { ParagraphMaxWidth } from "./styles";
import { gridSquares } from "../../styles/grid";

const CANBERRA_LINK = "https://www.instagram.com/reel/Ci_X4rUj7LG/";
const DENVER_LINK = "https://www.instagram.com/reel/ChtIP3GjQ2i/";
const NASHVILLE_LINK = "https://www.instagram.com/reel/CgVL7HuhX5Z/";
const BLUEMNTS_LINK = "https://www.instagram.com/reel/CcEne_ZDDwV/";

const TravelWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${(p) => p.theme.media("xl")} {
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
    }
`;

const StampsWrapper = styled.div`
    position: relative;
    margin: 16px;
    aspect-ratio: 4 / 3;

    ${(p) => p.theme.media("xl")} {
        aspect-ratio: 16 / 9;
    }
`;

const StampImageWrapper = styled(Hyperlink)`
    width: 50%;

    ${(p) => p.theme.media("xl")} {
        width: 42%;
    }

    :hover {
        z-index: 1;
        scale: 1.1;
    }
    transition: scale 250ms;
`;

const NashvilleImage = styled(StampImageWrapper)`
    position: absolute;
    top: 0px;
    right: 0px;
    rotate: 25deg;

    ${(p) => p.theme.media("xl")} {
        top: -96px;
        left: 10%;
        right: unset;
    }

    ${(p) => p.theme.media("xxl")} {
        top: -96px;
        left: 15%;
        right: unset;
    }
`;

const DenverImage = styled(StampImageWrapper)`
    position: absolute;
    bottom: 0px;
    left: 0px;
    rotate: -10deg;

    ${(p) => p.theme.media("xl")} {
        top: 20%;
        bottom: unset;
        left: -96px;
    }
`;

const CanberraImage = styled(StampImageWrapper)`
    position: absolute;
    top: 0px;
    right: 0px;
    rotate: 10deg;

    ${(p) => p.theme.media("xl")} {
        top: 10%;
        right: 0px;
    }
`;

const BluemountainsImage = styled(StampImageWrapper)`
    position: absolute;
    bottom: 0px;
    left: 0px;
    rotate: -10deg;

    ${(p) => p.theme.media("xl")} {
        bottom: -96px;
        left: unset;
        right: 30%;
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${Paragraph} {
        text-align: center;
    }

    ${(p) => p.theme.media("xl")} {
        ${Paragraph} {
            text-align: left;
        }

        align-items: start;
        margin-left: ${gridSquares(1)};
    }
`;

const StyledHeading = styled(Heading)`
    text-align: center;
    white-space: nowrap;

    ${(p) => p.theme.media("xl")} {
        text-align: left;
    }
`;

const StyledParagraph = styled(Paragraph)`
    ${ParagraphMaxWidth};
    margin-top: 0px;
`;

export const CareersPageTravel = () => {
    const data = useStaticQuery(graphql`
        query {
            nashvilleImage: file(
                relativePath: { eq: "careers/stamp_nashville.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            denverImage: file(
                relativePath: { eq: "careers/stamp_denver.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            canberraImage: file(
                relativePath: { eq: "careers/stamp_canberra.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }

            blueMountainsImage: file(
                relativePath: { eq: "careers/stamp_bluemnts.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }
        }
    `);

    const { nashvilleImage, denverImage, canberraImage, blueMountainsImage } =
        useMemo(() => {
            return {
                nashvilleImage: getImage(data.nashvilleImage),
                denverImage: getImage(data.denverImage),
                canberraImage: getImage(data.canberraImage),
                blueMountainsImage: getImage(data.blueMountainsImage),
            };
        }, [data]);

    return (
        <ThemeProvider theme={useSectionTheme("grey")}>
            <ThemedPageSection>
                <TravelWrapper>
                    <ShowWhenLessThan screenSize="xl">
                        <StampsWrapper>
                            <NashvilleImage href={NASHVILLE_LINK}>
                                <GatsbyImage
                                    image={nashvilleImage}
                                    alt="Agile 2022, Nashville"
                                />
                            </NashvilleImage>
                            <DenverImage href={DENVER_LINK}>
                                <GatsbyImage
                                    image={denverImage}
                                    alt="SAFe Summit, Denver"
                                />
                            </DenverImage>
                        </StampsWrapper>
                    </ShowWhenLessThan>
                    <ShowWhenGreaterThan screenSize="xl">
                        <StampsWrapper>
                            <NashvilleImage href={NASHVILLE_LINK}>
                                <GatsbyImage
                                    image={nashvilleImage}
                                    alt="Agile 2022, Nashville"
                                />
                            </NashvilleImage>
                            <DenverImage href={DENVER_LINK}>
                                <GatsbyImage
                                    image={denverImage}
                                    alt="SAFe Summit, Denver"
                                />
                            </DenverImage>
                            <CanberraImage href={CANBERRA_LINK}>
                                <GatsbyImage
                                    image={canberraImage}
                                    alt="Advance Easy Agile, Canberra"
                                />
                            </CanberraImage>
                            <BluemountainsImage href={BLUEMNTS_LINK}>
                                <GatsbyImage
                                    image={blueMountainsImage}
                                    alt="Advance Easy Agile, Blue Mountains"
                                />
                            </BluemountainsImage>
                        </StampsWrapper>
                    </ShowWhenGreaterThan>
                    <TextWrapper>
                        <StyledHeading level="h4">
                            Going places, together
                        </StyledHeading>
                        <StyledParagraph>
                            Connecting with each other and our customers is one
                            of our favourite things to do. Watch some of the
                            behind the scenes action and fun.
                        </StyledParagraph>
                    </TextWrapper>
                    <ShowWhenLessThan screenSize="xl">
                        <StampsWrapper>
                            <CanberraImage href={CANBERRA_LINK}>
                                <GatsbyImage
                                    image={canberraImage}
                                    alt="Advance Easy Agile, Canberra"
                                />
                            </CanberraImage>
                            <BluemountainsImage href={BLUEMNTS_LINK}>
                                <GatsbyImage
                                    image={blueMountainsImage}
                                    alt="Advance Easy Agile, Blue Mountains"
                                />
                            </BluemountainsImage>
                        </StampsWrapper>
                    </ShowWhenLessThan>
                </TravelWrapper>
            </ThemedPageSection>
        </ThemeProvider>
    );
};
