import React, { useContext } from "react";
import styled, { ThemeContext, ThemeProvider } from "styled-components";
import { ThemedPageSection } from ".";
import { useSectionTheme } from "../../styles/pageThemes";
import { ShowWhenGreaterThan, ShowWhenLessThan } from "../Breakpoints";
import { Hyperlink } from "../Link/Hyperlink";
import { Heading } from "../Typography/Heading";
import { LinkButton } from "../Typography/LinkButton";
import { Paragraph } from "../Typography/Paragraph";

interface ImpactTileProps {
    value: any;
    title: string;
    linkText: string;
    largeTile: boolean;
    subValue?: string;
    url: string;
    currencySymbol?: string;
}

const roomToReadProps: ImpactTileProps = {
    value: "$110k+",
    title: "Lifetime donations",
    linkText: "Room to read",
    url: "https://www.roomtoread.org/",
    largeTile: false,
};

const pledgeProps: ImpactTileProps = {
    value: "32",
    subValue: ``,
    title: "Volunteer days",
    linkText: "Pledge 1%",
    url: "/pledge/",
    largeTile: true,
};
const collectiveProps: ImpactTileProps = {
    currencySymbol: "$",
    value: "9k+",
    title: "Contributions since 2020",
    linkText: "Open collective",
    url: "https://opencollective.com/easyagile",
    largeTile: true,
};
const goodThnxProps: ImpactTileProps = {
    value: "$75k+",
    title: "Lifetime donations",
    linkText: "Good Thnx",
    url: "https://thnx.me/impact/#:~:text=Manager%20%40%20SAGE%20Group-,Easy%20Agile%20x,-Good%20Thnx%20with",
    largeTile: false,
};

const ImpactWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${(p) => p.theme.media("xl")} {
        flex-direction: row;
        gap: 1.5em;
    }
`;

const TextWrapper = styled.div`
    padding: 1.5em 0;
    text-align: center;

    ${(p) => p.theme.media("xl")} {
        text-align: left;
    }
`;

const StatsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    text-align: center;
    justify-content: center;
    flex-direction: column;

    ${(p) => p.theme.media("sm")} {
        gap: 16px;
    }
`;

const ImpactStat = styled(Hyperlink)<{ largeTile: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    border-radius: ${(p) => p.theme.borderRadius.l};
    background: ${(p) => p.theme.sectionTheme.backgroundColor};
    color: ${(p) => p.theme.sectionTheme.fontColor};

    padding: 16px;
    flex-grow: ${(p) => (p.largeTile ? 2 : 1)};

    ${(p) => p.theme.media("sm")} {
        flex-direction: ${(p) => (p.largeTile ? "row" : "column")};
    }
`;

const StyledLinkButton = styled(LinkButton)`
    p {
        font-weight: 400;
        text-transform: none;
    }
`;

const StyledHeading = styled(Heading)`
    margin: 0;
`;

const ImpactTileText = styled(Paragraph)`
    margin: 0;
    font-weight: 700;
`;

const ImpactTileRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    ${(p) => p.theme.media("sm")} {
        flex-direction: row;
        gap: 16px;
    }
`;

// This component is just for superscript symbols
const CurrencySymbol = styled.span`
    font-size: 16px;
    vertical-align: text-top;
    ${(p) => p.theme.media("md")} {
        font-size: 32px;
    }
`;

const ImpactTileTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    align-items: center;
    margin: auto;
    gap: 4px 0px;
    ${(p) => p.theme.media("md")} {
        width: 100%;
    }
`;

const ImpactTileValueContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ImpactTile: React.FC<ImpactTileProps> = ({
    currencySymbol,
    value,
    subValue,
    title,
    linkText,
    url,
    largeTile,
}) => {
    const theme = useContext(ThemeContext);

    return (
        <ImpactStat href={url} largeTile={largeTile}>
            <ImpactTileValueContainer>
                <StyledHeading
                    level={largeTile ? "h1" : "h3"}
                    forwardedAs="span"
                >
                    {currencySymbol && (
                        <CurrencySymbol>{currencySymbol}</CurrencySymbol>
                    )}
                    <span>{value}</span>
                </StyledHeading>
                {subValue && (
                    <StyledHeading level="h6" forwardedAs="span">
                        {subValue}
                    </StyledHeading>
                )}
            </ImpactTileValueContainer>
            <ImpactTileTextWrapper>
                <ImpactTileText>{title}</ImpactTileText>
                <StyledLinkButton
                    href={url}
                    color={theme.sectionTheme.fontColor}
                >
                    {linkText}
                </StyledLinkButton>
            </ImpactTileTextWrapper>
        </ImpactStat>
    );
};

export const CareersPageSocialImpact = () => (
    <ThemedPageSection>
        <ImpactWrapper>
            <TextWrapper>
                <Heading level="h4">Social Impact</Heading>
                <ShowWhenGreaterThan screenSize="xl">
                    <Paragraph>
                        We believe business should be good for the people,
                        community and planet. We are driven by giving back to
                        our community and our people.
                    </Paragraph>
                    <Paragraph>
                        Easy Agile commits to pledging 2% of profit: 1% going
                        directly to Room to Read plus another 1% reserved for
                        team member giving through Good Thnx and Open
                        Collective.
                    </Paragraph>
                    <Paragraph>
                        We are committed to understanding our impact as we
                        implement regenerative actions towards a more
                        sustainable future.
                    </Paragraph>
                </ShowWhenGreaterThan>
            </TextWrapper>
            <StatsWrapper>
                <ImpactTileRow>
                    <ThemeProvider theme={useSectionTheme("meteorShower")}>
                        <ImpactTile {...roomToReadProps} />
                    </ThemeProvider>
                    <ThemeProvider theme={useSectionTheme("flamingFlamingo")}>
                        <ImpactTile {...pledgeProps} />
                    </ThemeProvider>
                </ImpactTileRow>
                <ImpactTileRow>
                    <ThemeProvider theme={useSectionTheme("brightTurquoise")}>
                        <ImpactTile {...collectiveProps} />
                    </ThemeProvider>
                    <ThemeProvider theme={useSectionTheme("pompelmo")}>
                        <ImpactTile {...goodThnxProps} />
                    </ThemeProvider>
                </ImpactTileRow>
            </StatsWrapper>
            <ShowWhenLessThan screenSize="xl">
                <TextWrapper>
                    <Paragraph>
                        We believe business should be good for the people,
                        community and planet. We are driven by giving back to
                        our community and our people.
                    </Paragraph>
                    <Paragraph>
                        Easy Agile commits to pledging 2% of profit: 1% going
                        directly to Room to Read plus another 1% reserved for
                        team member giving through Good Thnx and Open
                        Collective.
                    </Paragraph>
                    <Paragraph>
                        We are committed to understanding our impact as we
                        implement regenerative actions towards a more
                        sustainable future.
                    </Paragraph>
                </TextWrapper>
            </ShowWhenLessThan>
        </ImpactWrapper>
    </ThemedPageSection>
);
