import React from "react";
import styled, { ThemeProvider, useTheme } from "styled-components";
import { ThemedPageSection } from ".";
import { useSectionTheme } from "../../styles/pageThemes";
import Button from "../Button";
import { Heading } from "../Typography/Heading";
import { Paragraph } from "../Typography/Paragraph";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { GridGutterRows } from "../GridGutters";

const PositionedImage = styled(GatsbyImage)`
    && {
        position: absolute;
        top: 0px;
        right: 0px;
    }
`;

const TextContainer = styled.div`
    z-index: 1;
`;
const HeroSectionLayoutContainer = styled.div<{ imageWidth; imageHeight }>`
    ${TextContainer} {
        margin-top: calc(80vw * ${(p) => p.imageHeight / p.imageWidth} - 16px);
    }
    ${PositionedImage} {
        width: 80vw;
    }

    /* 750*/
    ${(p) => p.theme.media("sm")} {
        ${TextContainer} {
            margin-top: calc(
                80vw * ${(p) => p.imageHeight / p.imageWidth} - 96px
            );
        }
        ${PositionedImage} {
            width: 80vw;
        }
    }

    ${(p) => p.theme.media("md")} {
        ${TextContainer} {
            margin-top: calc(
                70vw * ${(p) => p.imageHeight / p.imageWidth} - 96px
            );
            max-width: 50vw;
        }
        ${PositionedImage} {
            width: 70vw;
        }
    }
    /* 992 */
    ${(p) => p.theme.media("lg")} {
        ${TextContainer} {
            margin-top: calc(
                70vw * ${(p) => p.imageHeight / p.imageWidth} - 200px
            );
            max-width: 40vw;
        }
        ${PositionedImage} {
            width: 70vw;
        }
    }

    /* 1200 */
    ${(p) => p.theme.media("xl")} {
        height: 550px;
        ${TextContainer} {
            width: 400px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin-top: 0px;
        }
        ${PositionedImage} {
            width: 60vw;
        }
    }

    /* 1920 */
    ${(p) => p.theme.media("xxl")} {
        height: 817px;

        ${PositionedImage} {
            width: 70vw;
        }
    }
`;

const StyledHeading = styled(Heading)`
    margin: 0px 0px 24px 0px;

    ${(p) => p.theme.media("sm")} {
        width: 50%;
        margin: 0px 0px 32px 0px;
    }

    ${(p) => p.theme.media("md")} {
        width: 100%;
        margin: 0px 0px 50px 0px;
    }
`;

const StyledParagraph = styled(Paragraph)`
    margin: 0px 0px 60px 0px;
`;

// REVIEW - should we make all buttons not wrap by default
const NoWrapButton = styled(Button)`
    white-space: nowrap;
`;

export const CareersPageHeroSection = () => {
    const data = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "careers/hero_team_photo.png" }) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }
        }
    `);

    const fillerImage = getImage(data.image);

    const theme = useTheme();

    return (
        <ThemeProvider theme={useSectionTheme("deepViolet")}>
            <ThemedPageSection>
                <HeroSectionLayoutContainer
                    imageWidth={fillerImage.width}
                    imageHeight={fillerImage.height}
                >
                    <TextContainer>
                        <StyledHeading level="h1" levelStyle="h2">
                            We grow and succeed when we do it together.
                        </StyledHeading>
                        <StyledParagraph>
                            Come and help teams be agile with us
                        </StyledParagraph>
                        <NoWrapButton
                            onClick={() => {
                                const anchorEl = document.getElementById(
                                    "careers-page-job-board"
                                );

                                if (!anchorEl) return;

                                const clientRect =
                                    anchorEl.getBoundingClientRect();

                                // make sure to accomodate the scrollMargin
                                window.scrollTo({
                                    left: 0,
                                    top:
                                        clientRect.top -
                                        parseInt(theme.height.navBar, 10),
                                    behavior: "smooth",
                                });
                            }}
                        >
                            Find My Role
                        </NoWrapButton>
                    </TextContainer>
                    <PositionedImage
                        image={fillerImage}
                        alt="Team Photo from Advance Easy Agile, Canberra"
                    />
                    <GridGutterRows gutters={2} />
                </HeroSectionLayoutContainer>
            </ThemedPageSection>
        </ThemeProvider>
    );
};
