import styled from "styled-components";
import { PageSectionContainer } from "../PageSection";

export const ThemedPageSection = styled(PageSectionContainer)`
    background: ${(p) => p.theme.sectionTheme.backgroundColor};
    color: ${(p) => p.theme.sectionTheme.fontColor};

    box-shadow: -1px -1px 0px 1px ${(p) => p.theme.sectionTheme.backgroundColor}; // non blurry box shadow to make sure no background bleed
    padding-top: 64px;
    padding-bottom: 64px;
    overflow: hidden;

    scroll-margin-top: ${(p) => p.theme.height.navBar};
`;
