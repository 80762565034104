import React from "react";
import { Head } from "../components/Head";
import { CareersPageHeroSection } from "../components/Careers/CareersPageHeroSection";
import { CareersPageProductIntro } from "../components/Careers/CareersPageProductIntro";
import { CareersPageTeam } from "../components/Careers/CareersPageTeam";
import { CareersPageCultureBuilding } from "../components/Careers/CareersPageCultureBuilding";
import { CareersPageAwards } from "../components/Careers/CareersPageAwards";
import { CareersPageBenefits } from "../components/Careers/CareersPageBenefits";
import { CareersPageLocations } from "../components/Careers/CareersPageLocations";
import { CareersPageValues } from "../components/Careers/CareersPageValues";
import { CareersPageSocialImpact } from "../components/Careers/CareersPageSocialImpact";
import { CareersPageTravel } from "../components/Careers/CareersPageTravel";
import { CareersPageJobBoard } from "../components/Careers/CareersPageJobBoard";
import { Customers } from "../components/Customers";
import { CAREERS_TITLE } from "../constants/titles";
import { ContentGroups } from "../constants/contentGroups";

const CareersPage = () => (
    <>
        <Head
            contentGroup={ContentGroups.careers}
            title={CAREERS_TITLE}
            description="Come and help teams be agile with us!"
        />
        <CareersPageHeroSection />
        <CareersPageProductIntro />
        <CareersPageTeam />
        <CareersPageCultureBuilding />
        <CareersPageAwards />
        <CareersPageBenefits />
        <CareersPageLocations />
        <CareersPageValues />
        <CareersPageSocialImpact />
        <CareersPageTravel />
        <Customers />
        <CareersPageJobBoard />
    </>
);

export default CareersPage;
