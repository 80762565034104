import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { ThemedPageSection } from ".";
import { useSectionTheme } from "../../styles/pageThemes";
import { ShowWhenGreaterThan, ShowWhenLessThan } from "../Breakpoints";
import { YoutubeEmbed } from "../EmbeddedContent";
import { GridGutterRows } from "../GridGutters";
import { Heading } from "../Typography/Heading";
import { Paragraph } from "../Typography/Paragraph";
import { gridSquares } from "../../styles/grid";

const LayoutContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;

    gap: ${gridSquares(4)};
    align-items: center;
    grid-template-areas:
        "youtube"
        "textWrapper";

    & > :nth-child(1) {
        grid-area: textWrapper;
    }

    & > :nth-child(2) {
        grid-area: youtube;
    }

    ${(p) => p.theme.media("lg")} {
        grid-template-columns: 1fr 2fr;
        grid-template-areas: "textWrapper youtube";
    }
    ${(p) => p.theme.media("xl")} {
        grid-template-columns: 1fr 3fr;
        grid-template-areas: "textWrapper youtube";
    }
`;

const TextWrapper = styled.div``;

export const CareersPageCultureBuilding = () => (
    <ThemeProvider theme={useSectionTheme("meteorShower")}>
        <ThemedPageSection>
            <LayoutContainer>
                <TextWrapper>
                    <Heading level="h4">
                        Sustaining a positive team culture
                    </Heading>
                    <Paragraph>
                        Dave Elkan and Nick Muldoon, Co-CEOs and Co-founders on
                        building Easy Agile.
                    </Paragraph>
                </TextWrapper>

                <YoutubeEmbed
                    url="https://www.youtube.com/watch?v=LyDWtKU6UnM"
                    size="large"
                />
            </LayoutContainer>
            <GridGutterRows gutters={2} /> {/* padding for awards overflow */}
            <ShowWhenGreaterThan screenSize="md">
                <GridGutterRows gutters={2} />
            </ShowWhenGreaterThan>
        </ThemedPageSection>
    </ThemeProvider>
);
