import React from "react";
import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { ThemedPageSection } from ".";
import { Heading } from "../Typography/Heading";
import { Paragraph } from "../Typography/Paragraph";
import { LinkButton } from "../Typography/LinkButton";
import { UnderlinedLink } from "../Link/Hyperlink";
import { CAREERS_GENERAL_APPLY } from "../../constants/urls";
import topRightmage from "../../../content/imgs/careers/Triangle.svg";
import bottomLeftImage from "../../../content/imgs/careers/Circle_square.svg";
import { Disclaimer } from "../Typography/Disclaimer";
import { GridGutterRows } from "../GridGutters";
import { ParagraphMaxWidth } from "./styles";

const TextWrapper = styled.div`
    position: relative;
    z-index: 1;

    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
`;

const PositionedImage = styled.img<{ position: "top" | "bottom" }>`
    position: absolute;
    width: 200px;
    height: 200px;
    display: none;
    ${(p) => {
        if (p.position === "top") {
            return css`
                top: -45px;
                right: -90px;
                transform: rotate(112deg);
            `;
        }

        return css`
            width: 250px;
            height: 250px;
            bottom: -70px;
            left: -65px;
        `;
    }}

    ${(p) => p.theme.media("lg")} {
        display: block;
    }
`;

const SectionHeading = styled(Heading)`
    padding-top: 50px;
`;

const StyledParagraph = styled(Paragraph)`
    ${ParagraphMaxWidth}
`;

export const CareersPageJobBoard = () => {
    const data = useStaticQuery(graphql`
        query {
            allLever {
                edges {
                    node {
                        text
                        hostedUrl
                    }
                }
            }
        }
    `);

    const jobPostings = data?.allLever?.edges?.map((edge) => edge.node) || [];

    return (
        <ThemedPageSection id="careers-page-job-board">
            {/* <JobBoardWrapper> */}
            <TextWrapper>
                <SectionHeading level="h4" noBottomMargin>
                    Discover your next role
                </SectionHeading>
                <StyledParagraph>
                    At Easy Agile, we serve a global community and we need a
                    diverse team to do that successfully. We value people over
                    perfection.
                </StyledParagraph>
                {jobPostings.map((job) => (
                    <React.Fragment key={job.hostedUrl}>
                        <GridGutterRows gutters={2} />
                        <LinkButton href={job.hostedUrl}>{job.text}</LinkButton>
                    </React.Fragment>
                ))}

                <Paragraph noBottomMargin>
                    Don't see an opportunity that suits you?
                </Paragraph>
                <UnderlinedLink href={CAREERS_GENERAL_APPLY}>
                    <span>Let us know.</span>
                </UnderlinedLink>
                <GridGutterRows gutters={1} />
                <Disclaimer>
                    If you need any accomodations or adjustments throughout the
                    interview process and beyond, please let us know.
                </Disclaimer>
                <GridGutterRows gutters={2} />
            </TextWrapper>
            <PositionedImage position="top" src={topRightmage} />
            <PositionedImage position="bottom" src={bottomLeftImage} />
            {/* </JobBoardWrapper> */}
        </ThemedPageSection>
    );
};
