import { graphql, useStaticQuery } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import React from "react";
import styled, { keyframes, ThemeProvider } from "styled-components";
import { useSectionTheme } from "../../styles/pageThemes";
import { pageSectionContainerPadding } from "../PageSection";
import { Heading } from "../Typography/Heading";
import { ParagraphMaxWidth } from "./styles";
import { LinkButton } from "../Typography/LinkButton";
import { Paragraph } from "../Typography/Paragraph";
import { Flex } from "../ThemeFlex";
import { GridGutterRows } from "../GridGutters";

const faceBigness = 120;
const spaceBetweenImages = 16;
const imageStripPaddingX = spaceBetweenImages / 2; // Padding either side of each strip should add to look like the same spacing between individual images

const AuthorImage = styled.img`
    border-radius: ${(p) => p.theme.borderRadius.l};
    width: ${faceBigness}px;
    height: ${faceBigness}px;
    object-fit: cover;
`;

const imageStripScroll = keyframes`
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-200%);
    }
`;

interface TeamMembersStripProps {
    imagesCount: number;
}

const TeamMembersStrip = styled.div<TeamMembersStripProps>`
    padding: 0 ${imageStripPaddingX}px;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: ${spaceBetweenImages}px;
    animation: ${imageStripScroll} ${(p) => p.imagesCount}s linear infinite;
`;

const TeamMembersContainer = styled.div`
    height: ${faceBigness}px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
`;

const TeamMembers = ({ teamMembers }) => {
    const generateTeamMemberImages = (isSemantic = true) => {
        return teamMembers.map((author) => {
            const img = getSrc(author.image.asset);

            return (
                <AuthorImage
                    src={img}
                    key={author._id}
                    alt={isSemantic ? author.name : ""}
                />
            );
        });
    };

    const teamMemberImageArrays = [
        generateTeamMemberImages(true),
        generateTeamMemberImages(),
        generateTeamMemberImages(),
    ];

    const imagesCount = teamMembers.length * 3;

    return (
        <TeamMembersContainer>
            {teamMemberImageArrays.map((imgs, index) => (
                <TeamMembersStrip
                    key={`teamMember${index}`}
                    imagesCount={imagesCount}
                >
                    {imgs}
                </TeamMembersStrip>
            ))}
        </TeamMembersContainer>
    );
};

const ThemedPageSection = styled.div`
    position: relative;
    background: ${(p) => p.theme.sectionTheme.backgroundColor};
    color: ${(p) => p.theme.sectionTheme.fontColor};
    padding-top: 32px;
    padding-bottom: 32px;
    overflow: hidden;
    width: 100%;
    scroll-margin-top: ${(p) => p.theme.height.navBar};
    text-align: center;
`;

const StyledHeading = styled(Heading)`
    ${pageSectionContainerPadding}
`;

const ContainerPadding = styled.div`
    ${pageSectionContainerPadding}
`;
const StyledParagraph = styled(Paragraph)`
    ${ParagraphMaxWidth}
    margin-left: auto;
    margin-right: auto;
`;

const StyledLinkButton = styled(LinkButton)`
    display: inline-block;

    p {
        display: inline-block;
    }
`;

export const CareersPageTeam = () => {
    const {
        allSanityAuthor: { authors },
    } = useStaticQuery(graphql`
        query {
            allSanityAuthor(
                sort: { order: ASC, fields: startDate }
                filter: { inactive: { ne: true } }
            ) {
                authors: nodes {
                    name
                    startDate
                    _id
                    image {
                        asset {
                            gatsbyImageData(layout: CONSTRAINED, width: 120)
                        }
                    }
                }
            }
        }
    `);

    return (
        <ThemeProvider theme={useSectionTheme("meteorShower")}>
            <ThemedPageSection>
                <StyledHeading level="h2">
                    The best thing about Easy Agile
                </StyledHeading>
                <TeamMembers teamMembers={authors} />

                <ContainerPadding>
                    <GridGutterRows gutters={1} />
                    <StyledParagraph>
                        We are a curious, creative and diverse team driven by
                        our purpose. Energised by building great products
                        together, as well as supporting and celebrating each
                        other's journeys along the way!
                    </StyledParagraph>
                    <StyledLinkButton color={"currentColor"} href="/company">
                        Meet the team
                    </StyledLinkButton>
                </ContainerPadding>
            </ThemedPageSection>
        </ThemeProvider>
    );
};
