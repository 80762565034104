import React from "react";
import styled from "styled-components";
import { ParagraphMaxWidth } from "./styles";
import { ThemedPageSection } from ".";
import { Heading } from "../Typography/Heading";
import { Paragraph } from "../Typography/Paragraph";
import colocatedImage from "../../../content/imgs/careers/Co_located.svg";
import hybridImage from "../../../content/imgs/careers/Hybrid.svg";
import remoteImage from "../../../content/imgs/careers/Remote.svg";

const Strong = styled.span`
    font-weight: 700;
`;

const LocationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    ${(p) => p.theme.media("lg")} {
        justify-content: space-between;
        flex-direction: row;
    }
`;

const Locationitem = styled.div`
    display: flex;
    padding: 2em 1.5em 0;
    justify-content: space-between;
    flex-direction: column;

    ${(p) => p.theme.media("sm")} {
        flex-direction: row;
    }

    ${(p) => p.theme.media("lg")} {
        justify-content: start;
        width: 375px;
        align-items: center;
        flex-direction: column;
    }
`;

const LocationImage = styled.img`
    max-height: 230px;
    min-height: 175px;
    margin: auto;
`;

const SectionTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const AlignedParagraph = styled(Paragraph)`
    text-align: center;
    && {
        padding-bottom: 1em;
    }
    ${(p) => p.theme.media("sm")} {
        text-align: left;
    }
    ${(p) => p.theme.media("lg")} {
        text-align: center;
    }
`;

const TitleParagraph = styled(AlignedParagraph)`
    ${ParagraphMaxWidth}
    padding-bottom: 4em;
`;

const LocationHeading = styled(Heading)`
    margin-bottom: 0.8em;
    ${(p) => p.theme.media("sm")} {
        text-align: left;
    }
`;

const LocationInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${(p) => p.theme.media("sm")} {
        align-items: flex-start;
        margin-left: 12.5%;
    }
    ${(p) => p.theme.media("lg")} {
        margin-left: 0;
        align-items: center;
        padding-top: 1.5em;
    }
`;
const ImageContainer = styled.div`
    width: 252px;
    height: 230px;
    display: block;
    margin: auto;
    margin-top: 0;
    margin-bottom: 26px;
    ${(p) => p.theme.media("sm")} {
        margin-bottom: 0;
    }
    ${(p) => p.theme.media("lg")} {
        margin: 0;
    }
`;

export const CareersPageLocations = () => {
    return (
        <ThemedPageSection>
            <SectionTitleContainer>
                <Heading level="h4">Our locations</Heading>
                <TitleParagraph noMargin>
                    Work is wherever you are, that's why we offer{" "}
                    <Strong>
                        different work modes for you to choose from.
                    </Strong>{" "}
                    Do your best work from a place that's productive for you. We
                    all come together quarterly for 3 days of strategic
                    planning, alignment and team connection.
                </TitleParagraph>
            </SectionTitleContainer>
            <LocationWrapper>
                <Locationitem>
                    <ImageContainer>
                        <LocationImage src={colocatedImage} alt="Co-located" />
                    </ImageContainer>
                    <LocationInfo>
                        <LocationHeading level="h4">Co-located</LocationHeading>
                        <AlignedParagraph noMargin>
                            Work from our Wollongong (Dharawal) or Sydney
                            (Gadigal) offices. You will have a dedicated desk
                            for your screen, fancy keyboards, plants and all
                            things that make it feel like your space.
                        </AlignedParagraph>
                    </LocationInfo>
                </Locationitem>
                <Locationitem>
                    <ImageContainer>
                        <LocationImage src={hybridImage} alt="Hybrid" />
                    </ImageContainer>
                    <LocationInfo>
                        <LocationHeading level="h4">Hybrid</LocationHeading>
                        <AlignedParagraph noMargin>
                            Get the best of both worlds. We have hot desks
                            available in both our offices for those who prefer
                            to work from home and like to pop into the office.
                        </AlignedParagraph>
                    </LocationInfo>
                </Locationitem>
                <Locationitem>
                    <ImageContainer>
                        <LocationImage src={remoteImage} alt="Remote" />
                    </ImageContainer>
                    <LocationInfo>
                        <LocationHeading level="h4">Remote</LocationHeading>
                        <AlignedParagraph noMargin>
                            Work from anywhere in Australia! You can find our
                            current team in Melbourne, Nowra, Sydney, Tweed
                            Heads and lots of other places in between.
                        </AlignedParagraph>
                    </LocationInfo>
                </Locationitem>
            </LocationWrapper>
        </ThemedPageSection>
    );
};
