import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { ThemedPageSection } from ".";
import { useSectionTheme } from "../../styles/pageThemes";
import image from "../../../content/imgs/careers/IBA_Cert.png";
import work180_badge from "../../../content/imgs/careers/WORK180_BADGE.png";

import { Heading } from "../Typography/Heading";

const AwardsSection = styled(ThemedPageSection)`
    overflow: visible;
    z-index: 1;
    padding-top: 48px;
    padding-bottom: 48px;
`;

const Wrapper = styled.div`
    display: flex;
    position: relative;
`;

const ImageAwardContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    right: 0px;
    top: -27px;

    // Required for smaller screens as the image covers the text
    @media screen and (min-width: 576px) {
        right: 0px;
        top: -38px;
    }
    ${(p) => p.theme.media("md")} {
        top: -66px;
    }
    ${(p) => p.theme.media("lg")} {
        top: -80px;
    }
    ${(p) => p.theme.media("xxxl")} {
        top: -80px;
    }

    img {
        width: 101px;
        height: 101px;
        border-radius: 100vmax;
        margin: 6px;

        ${(p) => p.theme.media("md")} {
            width: 175px;
            height: 175px;
        }
        ${(p) => p.theme.media("lg")} {
            width: 205px;
            height: 205px;
        }
        ${(p) => p.theme.media("xxxl")} {
            width: 205px;
            height: 205px;
        }
    }
`;

const StyledHeading = styled(Heading)`
    margin: 0px;
    flex-grow: 1;
    padding-right: 45%;
    padding-top: 7px;
    padding-bottom: 7px;

    ${(p) => p.theme.media("sm")} {
        font-size: 24px;
        white-space: nowrap;
    }

    ${(p) => p.theme.media("md")} {
        white-space: nowrap;
        font-size: 24px;
        padding-right: 52%;
    }
    ${(p) => p.theme.media("lg")} {
        white-space: nowrap;
        padding-right: 25%;
    }
`;

export const CareersPageAwards = () => {
    return (
        <ThemeProvider theme={useSectionTheme("deepViolet")}>
            <AwardsSection>
                <Wrapper>
                    <StyledHeading level="h4">
                        Some recent recognition
                    </StyledHeading>
                    <ImageAwardContainer>
                        <img
                            style={{
                                padding: "2px",
                            }} /* hard code this to match the inset of the other picture*/
                            src={work180_badge}
                            alt="WORK180 Endorsed Employer 2023"
                        />
                        <img src={image} alt="Best in Workplace Culture 2022" />
                    </ImageAwardContainer>
                </Wrapper>
            </AwardsSection>
        </ThemeProvider>
    );
};
