import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ThemedPageSection } from ".";
import { Heading } from "../Typography/Heading";
import { Paragraph } from "../Typography/Paragraph";
import { graphql, useStaticQuery } from "gatsby";
import { ParagraphMaxWidth } from "./styles";
import { gridSquares } from "../../styles/grid";

const ValuesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;

    ${(p) => p.theme.media("xl")} {
        display: grid;
        grid-template-areas: "intro intro intro" "icons icons text";
        grid-template-columns: repeat(3, 1fr);
    }
`;

const StyledHeading = styled(Heading)`
    display: flex;
    justify-content: center;
`;

const StyledParagraph = styled(Paragraph)`
    text-align: center;
    ${ParagraphMaxWidth}
    margin-left: auto;
    margin-right: auto;
`;

const IntroWrapper = styled.div`
    grid-area: intro;
`;

const ValuesIconWrapper = styled.div`
    grid-area: icons;

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    margin-top: 16px;
    gap: 16px;

    max-width: 300px;
    margin-left: auto;
    margin-right: auto;

    ${(p) => p.theme.media("md")} {
        max-width: 400px;
    }
    ${(p) => p.theme.media("lg")} {
        max-width: 600px;
    }

    ${(p) => p.theme.media("xl")} {
        margin-left: unset;
        margin-right: ${gridSquares(3)};
        max-width: unset;
    }
`;

const ValuesTextWrapper = styled.div<{ longest: number }>`
    grid-area: text;

    position: relative;

    > :nth-child(${(p) => (p.longest > 0 ? p.longest : 1)}) {
        position: relative;
    }
`;

const ValueIcon = styled.img<{ showing: boolean }>`
    width: 100%;

    opacity: ${(p) => (p.showing ? "1" : "0.5")};
    scale: ${(p) => (p.showing ? "1.1" : "1")};

    transition: opacity 250ms, scale 250ms;
`;

const ValueText = styled.div<{ showing: boolean }>`
    position: absolute;
    top: 0px;
    width: 100%;

    opacity: ${(p) => (p.showing ? "1" : "0")};
    transition: opacity 250ms;

    ${(p) => p.theme.media("xl")} {
        top: 50%;
        translate: 0% -50%;

        ${StyledHeading} {
            justify-content: flex-start;
        }

        ${StyledParagraph} {
            text-align: left;
        }
    }
`;

export const CareersPageValues = () => {
    const [currentValue, setCurrentValue] = useState(valueData[0].name);

    const images = useStaticQuery(graphql`
        query {
            balance: file(relativePath: { eq: "careers/balance.svg" }) {
                publicURL
            }
            beTheCustomer: file(
                relativePath: { eq: "careers/be_the_customer.svg" }
            ) {
                publicURL
            }
            commit: file(relativePath: { eq: "careers/commit.svg" }) {
                publicURL
            }
            give: file(relativePath: { eq: "careers/give.svg" }) {
                publicURL
            }
            punch: file(relativePath: { eq: "careers/punch.svg" }) {
                publicURL
            }
            system2: file(relativePath: { eq: "careers/system2.svg" }) {
                publicURL
            }
        }
    `);

    return (
        <ThemedPageSection>
            <ValuesWrapper>
                <IntroWrapper>
                    <StyledHeading level="h4">Our values</StyledHeading>
                    <StyledParagraph>
                        Our values are our commitment to each other, our
                        customers, partners and community. They guide everything
                        we do and what we aspire to be.
                    </StyledParagraph>
                </IntroWrapper>

                <ValuesIconWrapper>
                    {valueData.map(({ name, heading }, index) => (
                        <ValueIcon
                            key={`value${index}`}
                            src={images[name].publicURL}
                            alt={heading}
                            onMouseEnter={() => setCurrentValue(name)}
                            onClick={() => setCurrentValue(name)}
                            showing={currentValue === name}
                        />
                    ))}
                </ValuesIconWrapper>

                <ValuesTextWrapper
                    longest={valueData.findIndex((value) => value.longest) + 1}
                >
                    {valueData.map(({ name, heading, text }) => (
                        <ValueText showing={currentValue === name}>
                            <StyledHeading level="h5" levelStyle="h4">
                                {heading}
                            </StyledHeading>
                            <StyledParagraph>{text}</StyledParagraph>
                        </ValueText>
                    ))}
                </ValuesTextWrapper>
            </ValuesWrapper>
        </ThemedPageSection>
    );
};

const valueData = [
    {
        name: "balance",
        heading: "Better with Balance",
        text: "We have fun as a team and maintain a sustainable pace. We understand and celebrate what balance means to each of us, in and out of the office.",
        longest: false,
    },
    {
        name: "give",
        heading: "Give Back",
        text: "We love to actively participate in our communities. Whether it's showing up, lending a hand, teaching a skill or donating dollars, Easy Agile gives back.",
        longest: false,
    },
    {
        name: "beTheCustomer",
        heading: "Be the customer",
        text: "Empathising with our customers gives us the understanding to deliver solutions that exceed their expectations.",
        longest: false,
    },
    {
        name: "punch",
        heading: "Punch above our weight",
        text: "We are a lean team, focused on working smart and delivering high impact solutions. We experiment knowing it is okay to fail as it gives us the opportunity to learn.",
        longest: true,
    },
    {
        name: "system2",
        heading: "Engage system 2",
        text: "We do the hard work to make life easier for our customers. We take the time to understand before we execute and we strive to be effective.",
        longest: false,
    },
    {
        name: "commit",
        heading: "Commit, as a team",
        text: "We grow and succeed when we do it together. We commit to bringing our whole selves to work, to looking after one another, and to engaging with authenticity and courage.",
        longest: false,
    },
];
