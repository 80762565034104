import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { ThemedPageSection } from ".";
import { useSectionTheme } from "../../styles/pageThemes";
import { Heading } from "../Typography/Heading";
import { Paragraph } from "../Typography/Paragraph";
import { UnderlinedLink } from "../Link/Hyperlink";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { gridSquares } from "../../styles/grid";

const PerksLayout = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${(p) => p.theme.media("lg")} {
        flex-direction: row;
        align-items: stretch;
    }
`;

const PerksImage = styled(GatsbyImage)`
    && {
        max-width: 50vw;
        margin-bottom: ${gridSquares(2)};

        ${(p) => p.theme.media("lg")} {
            margin-left: -15vw;
            margin-right: 5vw;
        }
    }
`;

const ColumnarList = styled.ul`
    column-count: 1;
    text-align: left;

    ${(p) => p.theme.media("md")} {
        column-count: 1;
        column-gap: 2em;
    }
    ${(p) => p.theme.media("lg")} {
        column-count: 2;
        column-gap: 4em;
    }
`;
const BenefitsAndPerksBlock = styled.div`
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;

    ${(p) => p.theme.media("md")} {
        align-items: center;
        text-align: center;
    }

    ${(p) => p.theme.media("lg")} {
        align-items: center;
        text-align: left;
    }
`;
const ListItem = styled.li`
    display: flex;
    align-items: center;
    margin-bottom: 0.8em;
    list-style: none;
    white-space: nowrap;

    ::before {
        content: "";
        display: inline-block;
        height: 20px;
        width: 20px;
        margin-right: 12px;
        background-image: url("/images/careers/check_mark.svg");
        background-size: contain;
    }
`;
const StyledHeading = styled(Heading)`
    margin: 0px;
`;
const StyledParagraph = styled(Paragraph)`
    margin-top: 1em;
    margin-bottom: 2em;
    max-width:80vw;
    ${(p) => p.theme.media("sm")} {
        unset;
    }
`;

export const CareersPageBenefits = () => {
    const data = useStaticQuery(graphql`
        query {
            image: file(
                relativePath: { eq: "careers/perks_illustration.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE)
                }
            }
        }
    `);

    const fillerImage = getImage(data.image);
    return (
        <ThemeProvider theme={useSectionTheme("grey")}>
            <ThemedPageSection>
                <PerksLayout>
                    <PerksImage
                        image={fillerImage}
                        alt="Benefits and Perks"
                        objectPosition={"right"}
                    />
                    <BenefitsAndPerksBlock>
                        <StyledHeading level="h2" levelStyle="h4">
                            Benefits and perks
                        </StyledHeading>
                        <StyledParagraph>
                            We are committed to providing an incredible
                            environment and employee experience where people can
                            grow, contribute and have fun along the way.
                        </StyledParagraph>
                        <ColumnarList>
                            <ListItem>Competitive salary</ListItem>
                            <ListItem>Additional 2% superannuation</ListItem>
                            <ListItem>
                                <UnderlinedLink href="/blog/ownership-at-easy-agile">
                                    Business equity (ESOP)
                                </UnderlinedLink>
                            </ListItem>
                            <ListItem>$5000 L&D budget</ListItem>
                            <ListItem>$2000 home office budget</ListItem>
                            <ListItem>Inception weeks</ListItem>
                            <ListItem>Employee Assistance Program</ListItem>
                            <ListItem>Paid parental leave</ListItem>
                            <ListItem>Unlimited book budget</ListItem>
                            <ListItem>4 paid volunteer days</ListItem>
                            <ListItem>1/2 day Fridays in summer</ListItem>
                            <ListItem>
                                <UnderlinedLink href="https://work180.com/en-au/for-women/employer/easy-agile">
                                    Work 180 Endorsed Employer
                                </UnderlinedLink>
                            </ListItem>
                        </ColumnarList>
                    </BenefitsAndPerksBlock>
                </PerksLayout>
            </ThemedPageSection>
        </ThemeProvider>
    );
};
